.makeStyles-root-1 .MuiToolbar-root {
    flex-wrap: wrap;
}

div[class*="makeStyles-error-"] .MuiOutlinedInput-notchedOutline,
div[class*="makeStyles-error-"] .MuiOutlinedInput-root:hover,
div[class*="makeStyles-error-"]
    .makeStyles-error-35
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #ff0000;
}

body .MuiCheckbox-colorSecondary.Mui-checked {
    color: #d10000;
}
.MuiFormControlLabel-root span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-weight: 700;
    color: #d10000;
}
.MuiFormControlLabel-labelPlacementStart span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-weight: 700;
    color: #000000;
}
/* checkbox heading */
.MuiTypography-h6 .MuiFormControlLabel-root span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-weight: 700;
    line-height: 1.3;
    color: #000000;
}

label[class*="makeStyles-switchLabel-"] .MuiFormControlLabel-labelPlacementStart {
    margin-left: 9px;
}

.MuiTabs-root .MuiTab-textColorInherit.Mui-selected {
    background: #ffffff;
    color: #ed0000;
    font-weight: 700;
    border-radius: 10px;
}

.MuiPaper-elevation4 .MuiTabs-indicator {
    background-color: unset;
}

#simple-tabpanel-0 #simple-tabpanel-0 .MuiAlert-icon {
    padding: 49px 12px;
    opacity: unset;
}

body .MuiIconButton-sizeSmall {
    padding: 18px;
}

#simple-tabpanel-0 .MuiPaper-root.MuiAlert-standardSuccess .MuiAlert-icon {
    padding-left: 20px;
    border-radius: 16px 0px 0px 16px;
    background-color: #4caf50;
    color: #f7f9fa;
}
#simple-tabpanel-0 .MuiPaper-root.MuiAlert-standardError .MuiAlert-icon {
    padding-left: 20px;
    border-radius: 16px 0px 0px 16px;
    background-color: #e40a0a;
    color: #f7f9fa;
}

#simple-tabpanel-4 .MuiPaper-root.MuiAlert-root.MuiAlert-filledSuccess.MuiPaper-elevation0 {
    background-color: #4caf50;
}

body span.MuiButton-label {
    text-transform: capitalize;
}

body .MuiAccordion-rounded,
body .MuiAccordion-rounded:last-child {
    border-radius: 16px;
}

body .MuiAccordion-rounded:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
body .MuiAccordion-rounded:last-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

body .MuiTableCell-head {
    font-weight: 700;
}

#simple-tabpanel-0 #simple-tabpanel-0 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1 {
    max-width: 9.393333%;
}

#simple-tabpanel-1 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1 {
    max-width: 19.393333%;
}
#simple-tabpanel-1 .Input-Energy .MuiFormControl-root.MuiTextField-root {
    width:max-content;
}

body .MuiTableCell-root {
    padding: 6px;
}

div#demo-simple-select-outlined {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom-width: 8px;
    border-radius: 40px;
    min-width: 120px;
}
body .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -14px) scale(0.75);
}
#simple-tabpanel-4 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: unset;
}
div[form-testid="DownloadButton"] .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl,
div[form-testid="DownloadButtonactive"] .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    border-radius: 40px;
}
label[form-label="Download-label"] {
    color: #e40a0a;
}
div[form-testid="DownloadButtonactive"] .MuiSelect-icon {
    fill: #e40a0a;
}
.Input-Energy.error .MuiInputAdornment-positionEnd {
    margin-left: -48px;
}

@media (min-width: 600px) {
    .MuiTabs-root .MuiTabs-centered .MuiTab-root {
        min-width: 50%;
    }
}
@media (min-width: 600px) {
    body .MuiTabs-scroller .MuiTab-root {
        min-width: 80px;
    }
}
